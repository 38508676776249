.app-container {
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
  overflow-x: hidden;
}

.nav{
  position: fixed;
  top: 0px;
  height: 5%;
  width: 100%;
  z-index: 99;
}

.bottom-nav{
  position: fixed;
  bottom: 20px;
  height: 5%;
  width: 100%;
  z-index: 99;
}


.link-container:hover{ cursor: pointer; }

.text-link{
  font-size: 22px;
  position: absolute;
  top: 60%;
}

.contact-link{ right: 6%; }

.about-link{ right: 13%; }

.structural-link{ right: 19%;}

.architecture-link{  right: 28%; }

.furniture-link{ right: 38%; }

.link-word{ position: relative; }

.link-word:hover{
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #e6d377, 0 0 40px #e6d377, 0 0 50px #e6d377, 0 0 60px #e6d377, 0 0 70px #e6d377;
}

.disabled-link{
  pointer-events: none;
}


@media (max-width: 1280px){
  .text-link{
    font-size: 17px !important;
  }
}

@media (max-width: 910px){
  .text-link{
    font-size: 15px !important;
  }
}

@media (min-width: 850px){
    .bottom-nav{ display: none; }
  }

@media (max-width: 850px){
  .nav{ display: none; }
  .contact-link{ left: 83%; }

  .about-link{ left: 69%; }

  .structural-link{ left: 49%;}

  .architecture-link{  left: 26%; }

  .furniture-link{ left: 7%; }

  .app-container {
    padding-right: 0px;
    padding-left: 0px;
  }
}



/*  begin old code

.top-level{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index:99;
}


.navbar.transparent.navbar-inverse .navbar-inner {
   border-width: 0px;
   -webkit-box-shadow: 0px 0px;
   box-shadow: 0px 0px;
   background-color: rgba(0,0,0,0.0);
   background-image: -webkit-gradient(linear, 50.00% 0.00%, 50.00% 100.00%, color-stop( 0% , rgba(0,0,0,0.00)),color-stop( 100% , rgba(0,0,0,0.00)));
   background-image: -webkit-linear-gradient(270deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);
   background-image: linear-gradient(180deg,rgba(0,0,0,0.00) 0%,rgba(0,0,0,0.00) 100%);
}

.nav-text-logo{
  max-width: 200px;
}

.nav-logo-text{
  font-family: Metropolis;
  color: white !important;
  font-size: 60px !important;
  background: -webkit-linear-gradient(top left, var(--ws-yellow),  var(--ws-yellow), white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-stretch: ultra-expanded;
}

.link-style{
  font-size: 20px;
  background: -webkit-linear-gradient(top left, var(--ws-yellow),  var(--ws-yellow), white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.link-style2{
      background: -webkit-linear-gradient(bottom right, var(--ws-yellow),  var(--ws-yellow), white);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

a.link-style:hover{
  text-decoration: none; }

a.link-style:hover{
    background: -webkit-linear-gradient(top left, var(--ws-red),  var(--ws-red), var(--ws-light-blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
a.link-style:active {
  color: #EA6D6D; }

a.nav-logo-text:hover{
  background: -webkit-linear-gradient(white, white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}





*/
