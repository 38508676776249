.furniture-container{
  text-align: center;
}

.furniture-dt-view{

}

.furniture-dt-bg{
  width: 100%;
}

.furniture-mb-view{
  display: none;
}

.furniture-mb-bg{
  width: 100%;
  margin-bottom: 20px;
}

@media (max-width: 850px){
  .furniture-dt-view{
    display: none;
  }
  .furniture-mb-view{
    display: contents;
  }
}


/* For Contact Page  */

.email-link{
  position: fixed;
  left: 8%;
  top: 50%;
  height: 200px;
  width: 400px;
}
