:root {
  --ws-grey: #232323;
  --ws-white: #f8f6ec;
  --ws-yellow: #F9E79F;
  --ws-red: #C2745D;
  --ws-steel-blue: #79B4CA;
  --ws-light-blue: #E3F6FC;
  --ws-pink: #FCE3E7;
}

@font-face {
  font-family: 'Metropolis';
  src: url('fonts/Metropolis.eot');
  src: url('fonts/Metropolis.eot') format('embedded-opentype'),
       url('fonts/Metropolis.woff2') format('woff2'),
       url('fonts/Metropolis.woff') format('woff'),
       url('fonts/Metropolis.ttf') format('truetype'),
       url('fonts/Metropolis.svg#Metropolis') format('svg');
}


body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: white;
  background-color: black;
  font-family: 'Old Standard TT', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem;
}
input[type=file] {
  width: 100%;
}


a {
  color: var(--ws-yellow);
}



/* font-family: 'Antic Didone', serif;

font-family: 'Old Standard TT', serif;

font-family: 'Poiret One', cursive; */
